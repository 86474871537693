import React, { Component } from 'react';
import { Typography} from '@material-ui/core';
// import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import { removeSpace } from '../helper';

const styles = {
    customCell:{
        fontFamily: "Noto Sans",
        fontSize: '11px',
        fontWeight: 'bold',
        letterSpacing: '1.2px',
        color: '#8C8C8C',
        overflow:'hidden'
    }
}

class TableHeaderCell extends Component{
    constructor(props){
        super(props);
        this.state = {
            disableTooltip:true,
            width:'',
            nowrap:true
        }
        this.cellRef = React.createRef();
    }

    componentDidMount(){
        if (this.cellRef.current.scrollWidth >= 160){
            this.setState({
                width:'160px',
                nowrap:false
            })
        }
        else if (this.cellRef.current.scrollWidth <= 80){
            this.setState({
                width:'80px',
                nowrap:false
            })
        }
        else{
            this.setState({
                width:this.cellRef.current.scrollWidth,
                nowrap:false
            })

        }
    }

    render(){
        const{item,classes} = this.props;
        return(
            <Typography ref={this.cellRef} tableheadercell={removeSpace(item)} className={classes.customCell} style={{width:this.state.width}} noWrap={this.state.nowrap}>
                {item}
            </Typography>
        )
    }

}
export default withStyles(styles)(TableHeaderCell);