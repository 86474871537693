import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { observer } from "mobx-react";
import Geosuggest from "react-geosuggest";
import TeamModal from "./TeamModal";
import del from "../../assets/garbage.svg";
import { NotificationManager } from "react-notifications";
import API, {APIROOT, configService} from "./../../constants";

const styles = {
  error: {
    color: "#ff0000",
    fontSize: "14px",
    paddingTop: "5px",
  },
  field: {
    border: "1px solid #999999",
    boxShadow: "none",
    borderRadius: "3px",
    width: "100% !important",
    padding: "10px",
  },
  action: {
    width: "100%",
    marginTop: "3rem",
    paddingTop: "2rem",
    justifyContent: 'flex-end',
    gap: '1rem',
    display: 'flex',
    borderTop: "1px solid rgb(0 0 0 / 10%)",
  },
  delete: {
    cursor: 'pointer',
    marginLeft: 7,
    position: 'absolute',
    right: '-13px',
    top: '13px'
  },
  title: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: 15,
  },
};

const Location = ({ classes, hideLocation }) => {
  const [isLocation, setIsLocation] = useState(false);
  const [error, setError] = useState(null);
  const [loc, setLocation] = useState([{location: "", location_coordinates: ""}]);
  
  useEffect( () => {
    checkApis()
  },[]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if(loc[0].location === ""){
      setError("Location field is required!")
    }else{
      const accID = localStorage.getItem('selectedAccount');
      const params = {
        jobsite_list: loc,
        account_id: accID
      }
      API.POST(params, configService, API.URLS.poi, handleLocationCallback);
    }
  }

  const handleLocationCallback = (res) => {
    if(res && res.status === 201){
      if(res.data && res.data.Success.length > 0){
        const Location = res.data.Success.length > 1 ? 'Locations' : 'Location';
        NotificationManager.success(`${Location} added successfully.`);
        checkApis();
        setIsLocation(true)
        setError("")
      } else if(res.data.Error.length > 0){
        res.data.Error.map( (msg)=> NotificationManager.error(msg))
      } else{
        NotificationManager.error("Something went wrong!");
      }
    }if(res && res.status === 400){
      res.data.jobsite_list.poi_name.map( d => NotificationManager.error(d))
    }
  }

  const checkApis = () => {
    API.GET(APIROOT,{},API.URLS.me,meCallback)
  }
  const meCallback = (res)=>{
    if(res && res.status === 200){
        localStorage.setItem('userData',JSON.stringify(res.data));
        if(res.data.initiated_jobsites){
          setIsLocation(true)
        }
    }
  };

  const onSuggestSelect = (i,e) => { 
    if(e !== undefined){
      let location = e.label;
      let coordinates = e.location.lat + ',' + e.location.lng;
      let newFormValues = [...loc];
      newFormValues[i]["location"] = location;
      newFormValues[i]["location_coordinates"] = coordinates;
      setLocation(newFormValues);
      setError("")
    }
  };
  // adds new input
  const addMoreLocation = () => {
    setLocation([
      ...loc,
      {
        location: "",
        location_coordinates: "",
      }
    ])
  }
  // removes input
  const removeMoreLocation = (index) => {
    if (loc.length !== 1) {
      const values = [...loc]
      values.splice(index, 1)
      setLocation(values)
    }
  }

  const backButton = () => {
    setIsLocation(false)
  }

  return (
    <>
    { !isLocation ?
    <div className="location-wrap">
      <h3 className={classes.title}>Enter business location</h3>
      <form onSubmit={handleSubmit}>
        {loc&& loc.map( (f, i) => {
          return (
            <div key={i} className="new-fields"><Geosuggest
          onSuggestSelect={(e, suggest) => onSuggestSelect(i, e)}
          placeholder={"Location"}
        />{loc.length !==1 && i > 0 && <img className={classes.delete} onClick={() => removeMoreLocation(i)} src={del} alt="Remove" />}</div>
          )
        } )}
        {error&&<div className={classes.error}>{error}</div>}
        <div className="text-right">
          <div className="addMore" onClick={addMoreLocation}>+Add more</div>
        </div>
        <div className={classes.action}>
          <button
            type="submit"
            className={`button ${classes.button}`}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    : <TeamModal hideLocation={hideLocation} backButton={backButton} /> }
    </>
  );
};

export default withStyles(styles)(observer(Location));
