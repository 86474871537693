import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import {Tooltip, Typography} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ChatIcon from '@material-ui/icons/ChatBubble';
import MailIcon from '@material-ui/icons/Email';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import SettingsActive from '../assets/Sidebar/configration-active.png';
import Undo from '../assets/undo.png';
import CheckBox from './checkbox';
import TableCell from './tableCell';
// import { Typography } from 'antd';
import TableHeaderCell from './tableHeaderCell';
import {removeSpace} from '../helper';

const Styles = theme =>({
    table:{
        width: '100%',
        textAlign:'left',
        lineHeight:'27px',
        borderSpacing:0
    },
    tableHeader:{
        boxShadow:' 0 1px 0 0 rgba(191, 191, 191, 0.5)',
        backgroundColor: '#f9f9f9',
        fontFamily: 'Noto Sans',
        fontSize: '11px',
        fontWeight: 'bold',
        letterSpacing: '1.2px',
        color: '#8c8c8c',
        '&.cell':{
            border:'1px solid #d8d8d8'
        }
    },
    cell:{
        padding:'10px',
        // textTransform:'capitalize',
        fontFamily: "Noto Sans",
        fontSize: "13px",
        color: "#5e5e5e",
        // minWidth:'160px',
        position:'relative'
    },
    oddChild:{
        background:'#f6f6f6'
    },
    orderBy:{
        display:'flex',
        flexDirection:'column'
    },
    icon:{
        display:'inline-block',
        marginLeft:'5px',
        '& svg':{
            fontSize: '19px',
            margin: '0 3px',
            color:'#52aef4',
            cursor:'pointer'
        }
    },
    redSignal:{
        width:'14px',
        height:'14px',
        borderRadius:'50%',
        backgroundColor:'#ff5454'
    },
    greenSignal:{
        width:'14px',
        height:'14px',
        borderRadius:'50%',
        backgroundColor:'#7ed321'
    },
    order:{
        transition: 'transform 1000ms'
    },
    down:{
        transform: 'rotate(180deg)'
    },
    popoverContainer:{
        position:'relative'
    },
    more:{
        display: 'inline-flex',
        alignItems: 'center',
    },
    moreIcon:{
        color:'#8c8c8c !important'
    },
    popoverTop:{
        margin: 0,
        borderBottom: '1px solid #dfdfdf',
        padding: '.5rem .75rem',
        cursor:'pointer',
        fontFamily: 'Noto Sans',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#373a3c',
        display: 'flex',
        justifyContent: 'space-between'

    },
    popoverBody:{
        padding:0,
        width: '200px'
    },
    green:{
        color:'#7ed321'
    },
    red:{
        color:'#ff5454'
    },
    tr:{
        height:'50px'
    },
    tdCell:{
        fontSize:'14px',
        minWidth: '80px',
        lineHeight: '17px'
    },
    thCell:{
        fontSize:'11px',
        textTransform:'uppercase',
        lineHeight:'14px',
        verticalAlign:'middle',
        height: '50px'
    },
    approve:{
        margin: '0 5px 0 0',
        color: '#6fbd19',
        cursor: 'pointer',
        fontSize:'12px',
        fontFamily: 'Noto Sans'
    },
    approved:{
        width:'100%',
        color: '#6fbd19',
        cursor: 'pointer',
        fontSize:'12px',
        fontFamily: 'Noto Sans'
    },
    approvedUndo:{
        margin:0
    },
    noActionrejected:{
        margin: '0 85px 0 0',
        color: '#fc5e5e',
        cursor: 'pointer',
        fontSize:'13px',
        fontFamily: 'Noto Sans'
    },
    reject:{
        margin: '0 0 0 5px',
        color: '#fc5e5e',
        cursor: 'pointer',
        fontSize:'12px',
        fontFamily: 'Noto Sans'
    },
    undo:{
        fontSize:'12px',
        color:'#2eaae3',
        margin:'0 0 0 5px',
        cursor:'pointer',
        '& img':{
            height: '11px',
            margin: '0 3px 0 0'
        }
    },
    checkbox:{
        width: '10px',
        height: '10px',
        margin: '0 15px 0 0px',
        display: 'inline-block',
        '& input':{
            position:'absolute',
            top:0,
            bottom:0,
            margin:'auto'
        }
    },
    tdWithCheckbox:{
        // display:'flex'
    },
    customCell:{
        maxWidth: '160px',
        minWidth: '80px',
        fontSize: '14px',
        fontFamily: "Noto Sans",
        fontWeight: '400',
        color: '#5e5e5e'
    },
    tooltip:{
        background:'#fff',
        color:'#5e5e5e',
        borderRadius:'6px',
        boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.17)',
        minWidth:'100px',
        '& p':{
            fontSize: '13px',
            margin: 0,
            padding: '14px 5px',
            lineHeight: '21px',
            maxWidth:'160px'
        }
    },
    tooltipArrow:{
        color:'#fff',
    },
    tooltipTitleList:{
        listStyle: 'none',
        padding: '10px 5px',
        fontSize: '13px',
        lineHeight: '21px',
        fontFamily: "Noto Sans",
        margin:'0'
    },
    highlighted:{
        color:'#28abe9'
    },
    table_action_cell:{
        width:"125px",
        float:'right'
    },
    titleWithOrderBy:{
        // maxWidth:'70%'
    },
    orderByTh:{
        // position:'relative'
    },
    orderByBlock:{
        flexWrap:'nowrap'
        // position:'absolute',
        // top:'10px'
    },
    approvedReject:{
        float:'left !important',
        padding:'20px 10px 0 10px'
    },
    plainText:{
        fontSize:'14px',
        fontFamily:'Noto Sans',
        fontWeight:'400',
        clor:'#5e5e5e'
    },
    titles:{
        maxWidth: '160px',
        minWidth: '80px',
        fontFamily: "Noto Sans",
        fontSize: '11px',
        fontWeight: 'bold',
        letterSpacing: '1.2px',
        color: '#8c8c8c',
    },
    headerCheckbox:{
        position:'relative'
    },
    checkboxHeaderContainer:{
        width:'100px'
    },
    actionImg:{
        width:'18px',
        cursor:'pointer'
    },
    staticHeader:{
        position:'sticky',
        top:0,
        zIndex:1
    }
});

class TableCustom extends Component{

    state = {
        open: false,
    }

    handlePopoverOpen = event => {
    //   setAnchorEl(event.currentTarget);
       
        this.setState((prevState)=>{
            return {open: !prevState.open}
        });
    };
  
    handlePopoverClose = () => {
    //   setAnchorEl(null);
        this.setState({
            open: false
        });
    };

    handleTooltip = (e)=>{
        console.log("Tooltip");
    }
  
    render(){
        const {classes,heading,data,rowsPerPage,orderCallback,staticHeader,
            callback,withCheckbox,singleSelectHandle,selectAllHandle,selectAll,noEmptyRow} = this.props;
        /**
         * classes: to access classes object, provided by Mui withStyles (or JSS)
         * heading: list of objects of table headers present
         * data: list of objects with keys of each object being headers present and
         *   values being data being shown for corresponding heading.
         * rowsPerPage: no of rows present currently on each page.
         * orderCallback: callback to be fired on clicking orderBy up down arrows present 
         *   with heading if orderBy flag is true. (Please refer heading of any 
         *   of the listing page e.g. account.js)
         * callback: basically common callback for every action present on table.
         * withCheckbox: flag if one wants to add checkbox to each row. Please refer to 
         *    timeOffrequest.js for example.
         * singleSelectHandle: callback fired when checkbox is present and user checks one row.
         * selectAllHandle: callback fired if selectAll checkbox is checked.
         * selectAll: value of selectAll checkbox present in header of the table.
         * 
         * Please note a row can not have more than one checkbox.
         */
        return(
                <table className={classes.table}>
                    <thead>
                        <tr className={clsx(classes.tableHeader,{[classes.staticHeader]:staticHeader})}>
                        {/* Header Row Starts Here */}
                            {heading.map((th,index)=>(
                                <th className={clsx(classes.cell,classes.thCell,{[classes.orderByTh]:th.orderBy})} key={th.title+'_'+index}>
                                {
                                    //if checkboxes are present in table we need a selectAll 
                                    //checkbox also.
                                    withCheckbox && index===0 
                                    ?<Grid className={classes.checkboxHeaderContainer} tableheadercell={removeSpace(th.title)}>
                                        <Grid className={clsx(classes.checkbox,classes.headerCheckbox)}>
                                        {/* below is selectAll checkbox */}
                                            <CheckBox checked={selectAll} checkidentifier={"select-all-"+removeSpace(th.title)} change={selectAllHandle}/>
                                        </Grid>
                                        {th.title}
                                    </Grid>
                                    :
                                    //currently we do not support orderBy and checkbox together
                                    //if the field is being renders has orderBy flag true.
                                    th.orderBy?
                                        <Grid className={classes.orderByBlock} container direction="row" alignItems="center">
                                            <div className={classes.titleWithOrderBy} tableheadercell={removeSpace(th.title)}>{th.title}</div>
                                            <div className={classes.orderBy}>
                                            {/* orderCallback is orderby call back sent from parent component (screen) with field name
                                            + will mean increasing order, - means decreasing order */}
                                                <ArrowUp className={clsx(classes.order,{[classes.down]:(!th.orderingUp)})} tableheaderorderby={removeSpace(th.title)+th.orderingUp?'-up':'-down'} onClick={()=>{orderCallback(th.field,!th.orderingUp)}} />
                                            </div>
                                        </Grid>
                                        :   
                                        //if my table header is not a special header means do not have checkbox and 
                                        //orderBy then it must follow the width rule. Explained in the end of file.
                                        //along with future scopes.
                                        <TableHeaderCell item={th.title} />
                                
                                }</th>
                            ))}
                        </tr>
                    </thead>
                    {/* Thats the end of header, Body starts below */}
                    <tbody>
                        {/* we will be showing no of rows = rowsPerpage exactly. 
                            If data length is less than rowsPerPage then empty rows
                            will be shown and if data length is more than rowsPerpage 
                            than user can navigate to different pages using pagination
                            bar (different component, used as a sibling to this table 
                            component). 
                            There are some exception to this behaviour as can be seen in
                            timeOffrequest and shiftRequestion modules due to the initial
                            requirements. But all those exceptions are being handled on 
                            the screen itself and not here in this component.
                        */}
                        {Array.apply(null,Array(parseInt(rowsPerPage))).map((item,$index)=>{
                            if (data[$index]){
                                /* If value exists in data for given $index */
                                const dataValues = Object.values(data[$index]); //All values of the $indexth value
                               
                                const dataRow = data[$index];
                                const dataKeys = Object.keys(data[$index]);//All keys of $indexth value
                                //{/* console.log(Math.floor(12/dataValues.length)); */}
                                return (
                                    <tr className={clsx(classes.tr,{[classes.oddChild]:($index%2 !== 0 && data[$index] )})} key={'table-row-'+$index}>
                                    {/*
                                        we will traversing headings again as we need to show data
                                        corresponding to each heading, hence for a data value to be
                                        shown in the table it must be exactly same key as the 
                                        value of 'field' key in corresponding heading object.
                                        e.g.: if heading is like:         
                                        {title:'ACCOUNT ID',field:"account_id",orderBy:false,orderingUp:true},
                                        then data object must have a key: account_id in order to show account_id
                                    */}
                                    {
                                        heading.map((th,$index2)=>{
                                            let item = dataRow[th.field];
                                            //handling the actions first.
                                            if (th.field === 'action'){
                                               
                                                {/* console.log("data.values", dataValues) */}
                                                return (
                                                    <td className={
                                                        clsx(
                                                            classes.cell,
                                                            classes.tdCell,
                                                            {[classes.approvedReject]:(
                                                                item.includes('approved')
                                                                ||item.includes('approve-reject')
                                                                ||item.includes('reject-undo')
                                                                ||item.includes('approved-undo')
                                                                ||item.includes('rejected'))}
                                                        )} 
                                                        key={"table-action-cell-"+$index2}>
                                                        <Grid container justify="flex-end" className={classes.table_action_cell}>
                                                            {item.includes('chat')?<span onClick={(e)=>callback({event:e,type:'chat',data:data[$index],index:$index})} className={classes.icon} tablecell="message"><Tooltip tablecelltip="message" title="Message"><ChatIcon/></Tooltip></span>:null}
                                                            {item.includes('mail')?<span onClick={(e)=>callback({event:e,type:'mail',data:data[$index],index:$index})} className={classes.icon} tablecell="mail"><Tooltip tablecelltip="mail" title="Mail"><MailIcon /></Tooltip></span>:null}
                                                            {item.includes('edit')?<span onClick={(e)=>callback({event:e,type:'edit',data:data[$index],index:$index})} className={classes.icon} tablecell="edit"><Tooltip tablecelltip="edit" title="Edit"><EditIcon/></Tooltip></span>:null}
                                                            {item.includes('manage')?<span onClick={(e)=>callback({event:e,type:'manage',data:data[$index],index:$index})} className={classes.icon} tablecell="manage"><Tooltip tablecelltip="manage" title="Manage"><img className={classes.actionImg} src={SettingsActive} alt="manage"/></Tooltip></span>:null}
                                                            {item.includes('approve-reject')?
                                                                <Grid xs={12}>
                                                                    <span onClick={(e)=>callback({event:e,type:'approve',data:data[$index],index:$index})} tablecell="approve" className={classes.approve} >APPROVE</span>
                                                                    <span onClick={(e)=>callback({event:e,type:'reject',data:data[$index],index:$index})} tablecell="reject" className={classes.reject}>REJECT</span>
                                                                </Grid>:null}
                                                            {item.includes('reject-undo')?
                                                                <Grid xs={12}>
                                                                    <span className={classes.reject} tablecell="rejected" >REJECTED</span>
                                                                    <span onClick={(e)=>callback({event:e,type:'undo',data:data[$index],index:$index})} className={classes.undo} tablecell="undo"><img src={Undo} alt="not found" />UNDO</span>
                                                                </Grid>:null}
                                                            {item.includes('approved-undo')?
                                                                <Grid xs={12}>
                                                                    <span className={clsx(classes.approved,classes.approvedUndo)} tablecell="approved">Approved</span>
                                                                    <span onClick={(e)=>callback({event:e,type:'undoApproved',data:data[$index],index:$index})} tablecell="undo" className={classes.undo}><img src={Undo} alt="not found" />UNDO</span>
                                                                </Grid>:null}
                                                            {item.includes('approved')?<span className={classes.approved} tablecell="approved">APPROVED</span>:null}
                                                            {item.includes('rejected')?<span className={classes.noActionrejected} tablecell="rejected">Rejected</span>:null}
                                                            {item.includes('switch')?<span onClick={(e)=>callback({event:e,type:'chat',data:data[$index],index:$index})} className={classes.icon} tablecell="switch"><Switch tablecellswitch={'data-'+$index+'switch'} checked={data[$index].is_active}/></span>:null}
                                                            {item.includes('plain_text')
                                                            ?   <Tooltip title={item[item.indexOf('plain_text')+2]} tabletip={removeSpace(item[item.indexOf('plain_text')+1])}>
                                                                    <Typography className={classes.plainText} tablecell={removeSpace(item[item.indexOf('plain_text')+1])} noWrap>
                                                                        {item[item.indexOf('plain_text')+1]}
                                                                    </Typography>
                                                                </Tooltip>
                                                            :   null}
                                                            {
                                                                item.includes('signal')?
                                                                <Grid 
                                                                    
                                                                    className={clsx(classes.icon,classes.more)}>
                                                                {/* {data[$index].is_active?<Grid className={classes.greenSignal}></Grid>:<Grid className={classes.redSignal}></Grid>} */}
                                                                    <div>
                                                                        <OverlayTrigger
                                                                            trigger="click"
                                                                            key="bottom"
                                                                            placement="bottom"
                                                                            rootClose={true}
                                                                            overlay={
                                                                                <Popover id={`popover-positioned-bottom`}>
                                                                                <Popover.Content className={classes.popoverBody}>
                                                                                {data[$index].is_active?(<div className={clsx(classes.popoverTop,classes.green)}><span popoption="active">Active</span><CheckIcon /></div>):(<p className={classes.popoverTop} onClick={(e)=>{callback({event:e,type:'activate',data:data[$index],index:$index}); document.body.click(); }} popoption="activate">Activate</p>)}
                                                                                {data[$index].is_active?(<p className={classes.popoverTop} popoption="deactivate" onClick={(e)=>{callback({event:e,type:'deactivate',data:data[$index],index:$index}); document.body.click(); }}>Deactivate</p>):(<div className={clsx(classes.popoverTop,classes.red)}><span popoption="inactive">Deactivated</span><CheckIcon /></div>)}

                                                                                </Popover.Content>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                        <span tablecell="more-icon"><MoreVertIcon className={classes.moreIcon} /></span>
                                                                        </OverlayTrigger>
                                                                        {/* ))} */}
                                                                    </div>
                                                                </Grid>:null}
                                                            </Grid>
                                                    </td>
                                                )
                                            }
                                            else if (th.field!=='is_active'){
                                                {/* console.log(item) */}
                                                /**
                                                there used to be a column in each and every table where
                                                we used to show a rounded bulb like element in every row
                                                color of that element used to be: red if data is not active
                                                and green if data is inactive, you can find its commented code
                                                in above block, although it was being accessed and shown with 
                                                actions (see above code) but it was not action hence it was a
                                                separate field and as we were not exactly showing the value of
                                                that field (we were changing color based on its value) so we 
                                                had to be cautious and not mix it up with other common fields.
                                                hence the check th.field!=='is_active'.

                                                Below is the code for data field (neither action nor is_active).
                                                */
                                                return(
                                                    <td key={'table-cell-'+$index2} className={clsx(classes.cell,classes.tdCell,{[classes.tdWithCheckbox]:(withCheckbox && $index2 === 0)})}>
                                                        {
                                                            // if withCheckbox is true we need to show checkbox and it
                                                            // has to be in first column only.
                                                            withCheckbox && $index2 === 0
                                                            ?   <Grid>
                                                                    <Grid className={classes.checkbox}>
                                                                        <CheckBox checked={data[$index].is_active} checkidentifier={'select-'+removeSpace(data[$index])} change={e=>{singleSelectHandle({index:$index,item:data[$index]})}} obj={{index:$index,item:data[$index]}} disabled={data[$index].is_disabled}/>
                                                                    </Grid>
                                                                    {item}
                                                                </Grid>
                                                            :
                                                        //if field must show tooltip then its value must be an object
                                                        //with atleast two keys: is_tooltip and title 
                                                        (!!item && !!(item.is_tooltip))
                                                            ?
                                                                    <Tooltip classes={{
                                                                        tooltip:classes.tooltip,
                                                                        arrow:classes.tooltipArrow
                                                                    }} 
                                                                    arrow
                                                                    tabletip={removeSpace(item.title)}
                                                                    title={
                                                                        <p>{item.title}</p>
                                                                    }><Typography className={classes.customCell} style={!!item.styles?JSON.parse(item.styles):{}} tablecell={removeSpace(item.title)} noWrap>{item.title}</Typography></Tooltip>
                                                            :
                                                            //if field is a list then it must be an object and with 
                                                            //atleast two keys: is_list and list and on frontend 
                                                            //it will be shown as oneItem + n more, see team table's
                                                            // Assigned Joibsite column for example.
                                                                (!!item && item.is_list)
                                                                ?   item.list.length ?
                                                                    
                                                                    <Tooltip classes={{
                                                                        tooltip:classes.tooltip,
                                                                        arrow:classes.tooltipArrow
                                                                    }} 
                                                                    arrow
                                                                    title={
                                                                        <ul className={classes.tooltipTitleList} tabletiplist="table-tip-list">

                                                                        {item.list.map((singleItem)=>
                                                                                <li tabletiplistitem={removeSpace(singleItem)}>{singleItem}</li>
                                                                        )}
                                                                        </ul>
                                                                    }>
                                                                        <Typography className={classes.customCell} tablecell="list" style={!!item.styles?JSON.parse(item.styles):{}}>
                                                                            {item.list[0]}
                                                                            <span className={classes.highlighted} tablecellplus={removeSpace(item.list[0])}>
                                                                                {(item.list.length>1?(' + '+(item.list.length-1).toString()):'')}
                                                                            </span>
                                                                        </Typography>
                                                                    </Tooltip>
                                                                    
                                                                :
                                                                     ''
                                                                :
                                                                /***just like headers if a data value is not a special data value
                                                                    means if it is NOT: 
                                                                        action, 
                                                                        is_active, 
                                                                        first value to be show if withCheckbox is true, 
                                                                        list and 
                                                                        if it must have tooltip
                                                                    than it must follow the width rule discribed at the end of file.
                                                                */
                                                                   <TableCell item={item} />
                                                        }
                                                    </td>
                                                )
                                            }
                                            
                                        }
                                    )
                                    }
                                        
                                    </tr>
                                )
                            }
                            else{
                                //empty row needed if rowsPerpage is more then total data values.
                                if(!noEmptyRow){
                                    return(
                                    <tr key={'empty-table-row-'+$index}>
                                        <td className={classes.cell}></td>
                                    </tr>
                                    )
                                }
                                else{
                                    return null
                                }
                            }
                            
                        })}

                        
                    </tbody>
                </table>
        );
    }
}

export default withStyles(Styles)(TableCustom);


/**
 * Width Rule: 
 *      For Header: Max width allowed is 160 px min width allowed in 80px 
 *                  if length goes beyond 160px then heading should be wraped into second line.
 *                  One can change min and max width allowed in tableHeaderCell.js file.
 *      
 *      For Data: Max width allowed is 160 px min width allowed in 80px 
 *                if length goes beyond 160px then value will be shown truncated and user must see
 *                a tooltip with complete value on hovering the text.
 *                One can change min and max width allowed in tablCell.js file.
 * 
 *      Future Scope: Currently width rule is not being applied if a value is special, in future
 *                    requirements might arise to apply these rules to those special values. Special
 *                    Special values are explained in comments above.
 * 
 */