import React, { Component, Fragment} from 'react';
import {observable} from 'mobx';
import { observer } from 'mobx-react';
import ClearIcon from '@material-ui/icons/Clear';
import {withStyles} from "@material-ui/core/styles/index";
import { Modal, Backdrop } from '@material-ui/core';

const styles = {
    container:{
        top:0,
        left:0,
        width:'100%',
        height: '100%',
        display: 'flex',
        position:'absolute',
        zIndex: 9999,
        justifyContent: 'center',
        alignItems: 'center',
    },
    box: {
        width: '100%',
        maxWidth: '375px',
        background: "#ffffff",
        borderRadius: '20px',
        padding: '40px 25px 25px',
        position: "relative"
    },
    closeBtnCtnt: {
        cursor: 'pointer',
        color:'#232323',
        fontSize:20,
        right: 10,
        paddingLeft:4,
        position: 'absolute',
        top: 10,
    }
}

class Modalbox extends Component{
    toggleHandler = () => {
        // state.isOpen = !state.isOpen
        //this.props.closeCallBack();
    };

    render(){
        const {isOpen, component, classes, closeCallBack} = this.props;
        return(
           <Fragment>
            <Modal aria-labelledby="modal-component" open={ isOpen }
                       closeAfterTransition
                       BackdropComponent={Backdrop}
                       BackdropProps={{
                           style:{
                               backgroundColor:'rgb(11 53 88 / 60%)',
                           }}
                        }
                >
            <div className={classes.container}>
                <div className={classes.box}>
                    {component}
                </div>
            </div>
            </Modal>
           </Fragment> 
        )
    }
}

export default withStyles(styles)(observer(Modalbox));