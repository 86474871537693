import React, { useState, useEffect,Component } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// import { CssBaseline, Grid } from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
import {NotificationManager} from 'react-notifications';
// import Divider from '@material-ui/core/Divider';
import Workchat_Notification from '../components/WorkChat_Notification';

import { ListItem, ListItemText, Drawer, List, Menu, MenuItem } from '@material-ui/core';

// import Image from './../assets/logo.png';
import Image from './../assets/rosternow.png';
// import NotificationBell from './../assets/bell.png';

import DashboardIcon from '../assets/Sidebar/dashboard-inactive.png';
import SchedulerIcon from '../assets/Sidebar/scheduler-inactive.png';
import AttendanceIcon from '../assets/Sidebar/Atendance-inactive.png';
import WorkchatIcon from '../assets/Sidebar/chat-inactive.png';
import SettingsIcon from '../assets/Sidebar/configration-inactive.png';
import ToolsIcon from '../assets/Sidebar/tools-inactive.png';
import ReportsIcon from '../assets/Sidebar/reports-inactive.png';
import PayrollIcon from '../assets/Sidebar/Payroll-inactive.png';

import DashboardActive from '../assets/Sidebar/dashboard-active.png';
import SchedulerActive from '../assets/Sidebar/scheduler-active.png';
import AttendanceActive from '../assets/Sidebar/Atendance-active.png';
import WorkchatActive from '../assets/Sidebar/chat-active.png';
import SettingsActive from '../assets/Sidebar/configration-active.png';
import ToolsActive from '../assets/Sidebar/tools-active.png';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ReportsActive from '../assets/Sidebar/reports-active.png';
import PayrollActive from '../assets/Sidebar/Payroll-active.png';
import CustomModal from './Modal/modalX';
import UserProfile from '../views/Configurations/Profile/UserProfile';
import MyAccount from '../views/Configurations/Profile/MyAccount';
import {checkPermissions} from '../helper';

import Modalbox from "./../components/Modalbox/Modalbox";
import LocationModal from "./../components/Modalbox/LocationModal";

const drawerWidth = 186;

const useStyles = makeStyles(theme => ({
    notificationBox:{
        position: 'absolute',
        top: '55px',
        padding: '20px',
        right: 20,
        zIndex: 9,
        boxShadow: '0 4px 9px 0 rgba(0, 0, 0, 0.2)',
        border: 'solid 1px #e6e6e6',
        backgroundColor: '#ffffff',
        width:350
    },
    notify:{
        color: '#373a3c',
        fontSize: 13,
        position: 'relative',
        fontFamily: 'Noto Sans',
        fontWeight: 700,
        borderTop: 'solid 1px #e6e6e6',
        padding: '10px 0 10px 20px',
    },
    notifyWrap: {
      listStyle: 'none',
      paddingLeft:0,
      overflowY: 'scroll',
      padding: 5,
      maxHeight:450
    },
    redDot:{
        backgroundColor: '#e93827',
        position: 'absolute',
        top:14,
        paddingRight: 0,
        marginLeft: -17,
        width: '10px',
        borderRadius: '50%',
        height: '10px'
    },
    grayDot:{
        backgroundColor: '#e0e0e0',
        position: 'absolute',
        top:14,
        paddingRight: 0,
        marginLeft: -17,
        width: '10px',
        borderRadius: '50%',
        height: '10px'
    },
    redDotTop:{
        fontSize: '70px',
        lineHeight: 0,
        backgroundColor: '#e93827',
        marginLeft: -30,
        top: 3,
        zIndex:1,
        right: -13,
        position: 'absolute',
        width: '10px',
        borderRadius: '50%',
        height: '10px'
    },
    notifyTitle:{
        fontSize: 16,
        fontFamily: 'Noto Sans',
        fontWeight: 700,
    },
    viewAll: {
        color: '#006BFF',
        fontSize: 12,
        float: 'right',
        fontFamily: 'Noto Sans',
        fontWeight: 700,
        display: 'none'
    },
    msg:{
        display: 'block',
        fontWeight: 500
    },
    root: {
        display: 'flex',
        height:0
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        // '& img':{
        //     width:'24px',
        //     height:'24px'
        // }
    },
    drawerOpen: {
        boxShadow: '4px 0 15px 0 rgba(122, 122, 122, 0.1)',
        paddingTop:10,
        paddingBottom:10,
        top:0,
        // top:70,
        zIndex:1,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        boxShadow: '4px 0 15px 0 rgba(122, 122, 122, 0.1)',
        // top:70,
        top:0,
        zIndex:1,
        paddingTop:10,
        paddingBottom:10,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    headerBack: {
        height:70,
        width:'100%',
        // top:0,
        // display:'flex',
        // flexDirection:'row',
        backgroundColor:'#fff',
        boxShadow:'4px 0 15px 0 rgba(122, 122, 122, 0.1)'
    },
    toolbar: {
        display:'flex',
        justifyContent: 'space-between',
        zIndex:100
    },
    sideOuterBox: {
        height:60,
        paddingLeft:23,
        '& img':{
            maxWidth:'24px'
        }
    },
    sideTextBox: {
        fontSize:14,
        fontFamily:'Noto Sans',
        padding:32,
        color:'#959696'
    },
    sideTextBoxActive: {
        fontSize:14,
        fontFamily:'Noto Sans',
        padding:32,
        color:'#006BFF',
    },
    selected:{
        backgroundColor:'#006BFF',
        height:60,
        width:5
    },
    item:{
        '&:before': {

        }
    },
    profileBox: {
        color:'#000',
        width: '42%',
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'center',
        height:70,
    },
    profile: {

    },
    content: {
        // position:'absolute',
        position:'relative',
        flexGrow: 1,
        width:'calc(100% - 73px) !important',
        right:0,
        // marginTop:'70px'
    },
    logoSelectContainer:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between'
    },
    accountList:{
        borderRadius: "3px",
        // border: "solid 1px #cccccc",
        backgroundColor: "#ffffff",
        fontFamily: "Noto Sans",
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "capitalize",
        fontSize:'12px',
        // width: "30%",
        margin: '0 15px',
        height: "35px",
        float: "right",
        color: "#666666",
        '& select':{
            padding: '0 10px',
            height: '100%'
        },
        '&::before':{
           border:'none !important' 
        },
        '&::after':{
            border:'none !important' 
         }
    },
    accountWrapper:{
        width: 'auto',
        padding: '0 0 0 15px',
        display: 'flex',
        alignItems: 'center'
    }

}));

function Logo(props){
    return(
        <img src={Image}
             onClick={() => props.history.push('/dashboard')}
             alt={''}
             style={{
                 position:'absolute',
                 left:28,
                 top:16,
                  cursor: 'pointer'
             }}/>
    )
}

function Profile(props){
    const classes = useStyles();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [openProfile, setProfile] = React.useState(false);
    const [openMyAccount, setMyAccount] = React.useState(false);

    const handleChange = event => {
        setAuth(event.target.checked);
    };

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        setProfile(true);
    };

    const handleMyAccount = () => {
        setMyAccount(true);
    };

    const logOut = () => {
        localStorage.clear();
        props.history.push('/');
    };
    const hideProfile = ()=>{
        setProfile(false);
    }
    const hideProfileSuccess = ()=>{
        setProfile(false);
    }
    const hideMyAccount = ()=>{
        setMyAccount(false);
    }
    const hideMyAccountSuccess = ()=>{
        setMyAccount(false);
    }
    var userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;
    var profile_pic = userData.profile_pic;
    var package_payment_success = userData.package_payment_success;
    var isClient = userData.role_importance === 0;
    return(
        <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                // disabled = {isClient?!package_payment_success:false}
                color="inherit"
            >
                { profile_pic ? <img width="24" height="24" style={{ borderRadius:'50%' }} src={ profile_pic } /> : <AccountCircle /> }
            </IconButton>
            
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                // MenuProps={{
                disableScrollLock={true}
                // }}
            >
                {(package_payment_success || !isClient)&&<MenuItem onClick={handleProfile}>My Profile</MenuItem>}
                {((JSON.parse(localStorage.getItem('userData')).role_importance === 0 && package_payment_success) || JSON.parse(localStorage.getItem('userData')).is_superadmin)? 
                    <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
                :null}
                <MenuItem onClick={logOut}>Log out</MenuItem>
            </Menu>
            <CustomModal isOpen={openProfile} component={<UserProfile closeCallBack={hideProfile}/>} closeCallBack={hideProfileSuccess}/>
            <CustomModal isOpen={openMyAccount} component={<MyAccount history={props.history} onClose={handleClose} closeCallBack={hideMyAccount}/>} closeCallBack={hideMyAccountSuccess}/>
        </div>
    )
}

class Header extends Component {
    
    // state = {
    //     accountList:[],
    //     account_id:''
    // }

    componentDidMount(){
        // API.GET(configService,{remove_pagination:true,is_active:true},API.URLS.accounts,(res)=>{
        //     let account_id = JSON.parse(localStorage.getItem('selectedAccount'));
        //     this.setState({
        //         accountList:res.data
        //     });
        //     if ((!!account_id && account_id !== null)){
        //         this.setState({
        //             account_id:account_id
        //         });
        //         console.log(account_id);
        //     }
        //     // else{

        //     // }
        // },false,true);
    }
    changeAccount = (e)=>{
        // this.setState({
        //     account_id:e.target.value
        // },()=>{
        //     localStorage.setItem('selectedAccount',this.state.account_id);
        //     // this.props.changeAccountCallback(this.state.account_id);
        //     let location = this.props.history.location;
        //     console.log(this.props,this.props.history,location.pathname)
        //     this.props.history.push('/refresh'+location.pathname+location.search);
        // });
        // console.log(this.props,this.props.location)
    }
    render(){
        const { classes, history } = this.props;
        // const isClient = JSON.parse(localStorage.getItem('userData')).role_importance ===0;
        return(
            <div className={classes.logoSelectContainer} >
                <div><Logo history={history}/></div>
                {/* {isClient && <div className={classes.accountWrapper}>
                    <Select
                        native
                            onChange={this.changeAccount}
                            className={classes.accountList}
                            value={this.state.account_id}
                            
                        >
                        <option value="" disabled>Select Account</option>
                        {this.state.accountList.map((account,index)=>{
                            return <option key={index} value={account.id}>{account.account_name}</option>
                        })}
                    </Select>
                </div>} */}
            </div>
        )
    }
}



function Sidelist(props) {
    const { classes, form, setValues, selectedIndex,selectedAccount } = props;
    const updateField = (value) => {
        setValues({
            ...form,
            'screen': value
        });

        value = value.toLowerCase();
        props.history.push(value);
    };
    const isSuperAdmin = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).is_superadmin : null;
    return (
        <List>
            <ListItem
                button
                className={classes.sideOuterBox}
                sidebaroption="RosterNow-test"
                onClick={() => {updateField('Scheduler'); props.history.push('refresh'+props.history.location.pathname+"?tab=0")}}
            >
                {/*{form.screen==='Dashboard'?<img src={DashboardActive}/>:<img src={DashboardIcon}/>}*/}
                {form.screen==='Scheduler'?<img src={Image} className="miniLogo" />:<img src={Image}  className="miniLogo" />}
                <ListItemText disableTypography primary={'RosterNow'} className={classes.sideTextBox}/>
            </ListItem>


            <ListItem
                button
                className={classes.sideOuterBox}
                onClick={() => updateField('Dashboard')}
                sidebaroption="dash-board-icon"
            >
                {form.screen==='Dashboard'?<img src={DashboardActive}/>:<img src={DashboardIcon}/>}
                <ListItemText disableTypography primary={'Dashboard'} className={form.screen==='Dashboard'?classes.sideTextBoxActive:classes.sideTextBox}/>
            </ListItem>

            {(
                checkPermissions('can_view_shift')||checkPermissions('can_view_time_off_request')||checkPermissions('can_view_shift_request')
            ) && <ListItem
                button
                className={classes.sideOuterBox}
                onClick={() => updateField('Scheduler')}
                sidebaroption="scheduler-icon"
            >
                {form.screen==='Scheduler'?<img src={SchedulerActive}/>:<img src={SchedulerIcon}/>}
                <ListItemText disableTypography primary={'Scheduler'} className={form.screen==='Scheduler'?classes.sideTextBoxActive:classes.sideTextBox}/>
            </ListItem>}

            {checkPermissions('can_view_attendance') && <ListItem
                button
                className={classes.sideOuterBox}
                onClick={() => updateField('Attendance')}
                sidebaroption="attendance-icon"
            >
                {form.screen==='Attendance'?<img src={AttendanceActive}/>:<img src={AttendanceIcon}/>}
                <ListItemText disableTypography primary={'Attendance'} className={form.screen==='Attendance'?classes.sideTextBoxActive:classes.sideTextBox}/>
            </ListItem>}

            {checkPermissions('can_chat') && <ListItem
                button
                className={classes.sideOuterBox}
                onClick={() => updateField('Workchat')}
                sidebaroption="work-chat-icon"
            >
                {form.screen==='Workchat'?<img src={WorkchatActive}/>:<img src={WorkchatIcon}/>}
                <ListItemText disableTypography primary={'Workchat'}  className={form.screen==='Workchat'?classes.sideTextBoxActive:classes.sideTextBox}/>
            </ListItem>}

            {(checkPermissions('can_view_accounts')
                ||checkPermissions('can_view_brand')
                ||checkPermissions('can_view_poi')
                ||checkPermissions('can_view_team')
                ||checkPermissions('can_view_user')
                ||checkPermissions('can_view_role')
                ||checkPermissions('can_view_geographical_area'))
            &&<ListItem
                button
                className={classes.sideOuterBox}
                onClick={() => updateField('Settings')}
                sidebaroption="settings-icon"
            >
                {form.screen==='Settings'?<img src={SettingsActive}/>:<img src={SettingsIcon}/>}
                <ListItemText disableTypography primary={'Settings'} className={form.screen==='Settings'?classes.sideTextBoxActive:classes.sideTextBox}/>
            </ListItem>}
            {(
                checkPermissions('can_view_shift')||checkPermissions('can_view_time_off_request')||checkPermissions('can_view_shift_request')
            ) && <ListItem
                button
                className={classes.sideOuterBox}
                onClick={() => updateField('Payroll')}
                sidebaroption="payroll-icon"
            >
                {form.screen==='Payroll'?<img src={PayrollActive}/>:<img src={PayrollIcon}/>}
                <ListItemText disableTypography primary={'Payroll'} className={form.screen==='Payroll'?classes.sideTextBoxActive:classes.sideTextBox}/>
            </ListItem>}

            {(isSuperAdmin) && <ListItem
                button
                className={classes.sideOuterBox}
                onClick={() => updateField('Tools')}
                sidebaroption="tools-icon"
            >
                {form.screen==='Tools'?<img src={ToolsActive}/>:<img src={ToolsIcon}/>}
                <ListItemText disableTypography primary={'Tools'} className={form.screen==='Tools'?classes.sideTextBoxActive:classes.sideTextBox}/>
            </ListItem>}
             <ListItem
                button
                className={classes.sideOuterBox}
                onClick={() => updateField('Reports')}
                sidebaroption="report-icon"
            >
                {form.screen==='Reports'?<img src={ReportsActive}/>:<img src={ReportsIcon}/>}
                <ListItemText disableTypography primary={'Reports'} className={form.screen==='Reports'?classes.sideTextBoxActive:classes.sideTextBox}/>
            </ListItem>



        </List>
    )
}

export default function MiniDrawer(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [redDot, setredDot] = React.useState(false);
    const [notificationBox, setnotificationBox] = React.useState(false);
    const [notifications, setNotifications] = React.useState([]);
    const [form, setValues] = useState({
        screen: props.screen
    });
   // console.log(form)
    const [accountList,setAccountList] = React.useState([]);
    const [account_id,setAccountId] = React.useState(localStorage.getItem('selectedAccount'));

    
    let auth = localStorage.getItem('Token');
    let username = null;

    if(auth === null){
        setTimeout(NotificationManager.error('Session Expired'), 10000);
        setTimeout(props.history.push('/'), 3000);
    }
    else{
        username = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).first_name: null;
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    
    const [location, setLocation] = React.useState(true);
    const hideLocation = ()=>{
        setLocation(false);
    };
    const user = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;
    return (
        <div className={classes.root}>
            { user && (!user.initiated_jobsites || !user.initiated_teams) &&<Modalbox isOpen={location} component={<LocationModal hideLocation={hideLocation} />} closeCallBack={hideLocation}/>
          }
           
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })
                }}
                open={open}
                onMouseOver={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
            >

                <Sidelist classes={classes} form={form} setValues={setValues} history={props.history}/>

            </Drawer>
            <div style={{width:'100%'}} className={classes.content}>
            {React.cloneElement(props.component,{history:props.history, username:username})}
            </div>
            {props.screen!=='Workchat'?<Workchat_Notification/>:null}
        </div>
    );

}
