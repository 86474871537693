import React, { Component, Fragment } from 'react';
import { Typography, Button, Grid, Avatar, Snackbar, TextField, MenuItem, Checkbox, createMuiTheme, MuiThemeProvider, Select, Chip, Input, OutlinedInput, FormControl, InputLabel, InputAdornment, FormHelperText} from '@material-ui/core';
import {observer} from 'mobx-react';
import styles from './styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {withStyles} from "@material-ui/core/styles/index";
import TableCustom from '../../../components/table';
import validatorjs from 'validatorjs';
import { observable, computed } from 'mobx';
import {format, lastDayOfMonth, startOfWeek, lastDayOfWeek, startOfMonth, endOfMonth, subMonths, subWeeks, addWeeks, addMonths} from 'date-fns';
import MySnackbarContentWrapper from './../../../components/Notification/snackbar';
import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import Photo from './../../../assets/user.png';
import Download from './../../../assets/download.png';
import cardBottom from '../../../assets/card_bottom_dsigner.png';
import API, {configService, parseErrors, schedularService, APIROOT } from "../../../constants";
import {NotificationManager} from "react-notifications";
import difference from "../../../utils/diff";

const state = observable({
    data:[
        
    ],
    heading:[
        {title:'Date',field:"transaction_date"},
        {title:'Order Id',field:'order_id'},
        {title:'Package Name',field:'package_name'},
        {title:'amount',field:'amount'},
        {title:'  ',field:'invoice'}
    ],
    rowsPerPage:10,
    totalCount:1,
    currentPage:0, //Indexed from 0
    gotToPage:1,
    rowOptions:[10,15,30],
    packages: []
})

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#006BFF',
        },
    }
});

const getPackageById = (packageId)=>{
    if (!!state.packages){
        let pkg = state.packages.filter((p)=>{
            return p.id === packageId
        })
        return pkg[0];    
    }
}

class MyAccount extends Component{
    constructor(props){
        super(props);
        this.localData = {};
        this.package = {};
        this.state = {
            packagename:'',
            packageamount: null,
            no_of_users: 0,
        }
   }


    componentDidMount(){
        let $this = this;
        $this.localData = JSON.parse(localStorage.getItem('userData')); 
        $this.fetch();
    }
    fetch = ()=>{
        let $this = this;
        API.GET(configService,{},API.URLS.packages,function(res) {
            state.packages = res.data.results;
        });
        API.GET(APIROOT, {}, API.URLS.me, function(me){ 
            if(me.data.package_id){API.GET(configService, {}, API.URLS.packages+me.data.package_id+'/' , $this.handleGetCallBack)}
        });
        API.GET(APIROOT, {}, API.URLS.transactionlist, function(res){
            if (res && res.status === 200){
                state.totalCount = res.data.count;
                let list = res.data.results;
                let tmpData = [], account_ids='', package_ids;
                list.map( ids => {
                  account_ids = account_ids + ids.package_id+',';
                });
                account_ids = account_ids ? account_ids.replace(/,\s*$/, ""): 0;
                account_ids = Array.from(new Set(account_ids.split(','))).toString();
                API.GET(configService, {ids:account_ids}, API.URLS.packagesgetbymultipleids , (transids) => {
                    if(transids && transids.status === 200){
                        for (let i = 0;i<list.length;i++){
                            //console.log("Data ", list[i].package_id, getPackageById(list[i].package_id).package_name);
                            let obj = {};
                            obj.transaction_date = format(new Date(list[i].invoice_date), "MMM dd, Y");
                            obj.order_id = '#'+list[i].paytabs_response.pt_invoice_id;
                            obj.package_name = getPackageById(list[i].package_id).package_name;
                            obj.amount = list[i].paytabs_response.currency+' '+list[i].paytabs_response.amount;
                            obj.invoice = ( <a href={list[i].invoice_path} target="_blank"><img src={Download}/></a> );
                            tmpData.push(obj);
                        }
                    }

                    state.data = tmpData;
                });
            }
        });
    }
    handleGetCallBack=(res)=>{
        if (res && res.status === 200){
            this.setState({
                packagename: res.data.package_name,
                no_of_users: res.data.number_of_users,
                packageamount: res.data.amount.toFixed(2),
            });         
        }
    }
    handleChangePackage = (res) => {
        this.props.closeCallBack();
        this.props.onClose();
        this.props.history.push('/selectpackage');
    }
    handleRenewPackage = (res) => {
        let currUser = JSON.parse(localStorage.getItem("userData"));
        let userID = currUser.id;
        let params = {
          amount: parseInt(this.state.packageamount)
        };

        if(parseInt(this.state.packageamount) != 0){
            API.POST(params,APIROOT, API.URLS.createpaypage, (response) => {

                let localData = JSON.parse(localStorage.getItem('userData'));
                localData.p_id = response.data.p_id;
                localData.package_name = this.state.packagename;
                localData.package_id = currUser.package_id;
                localStorage.setItem('userData',JSON.stringify(localData));
                window.location.href = response.data.payment_url;
            });
        }else{
            let freePackageParams={
                package_id: currUser.package_id,
                package_payment_success: true
            }
            API.PATCH(APIROOT, freePackageParams, API.URLS.user+userID+'/', (response) => {
                let localData = JSON.parse(localStorage.getItem('userData'));
                localData.p_id = 0;
                localData.package_name = this.state.packagename;
                localData.package_id = currUser.package_id;
                localData.package_payment_success = true;
                localStorage.setItem('userData',JSON.stringify(localData));
                this.props.history.push('/settings');
            });
        }
    }    
    render(){
        const { classes } = this.props;
        return(
            <Fragment>
                <MuiThemeProvider theme={theme}>
                    <Grid
                        container
                        alignItems={'flex-start'}
                        justify={'center'}
                        direction={'row'}
                        style={{overflow:'scroll', margin:'0px 0px 0px 30px'}}
                    >
                        <Grid item xs><Typography className={classes.headerText}>My Account</Typography></Grid>
                        <Grid item xs>
                           <h3 className={classes.daysLeft}> { this.localData.remaining_days ? this.localData.remaining_days + ' Days Left' : ' ' }</h3>
                        </Grid>

                        <Grid container alignItems={'center'} justify={'flex-start'} direction={'row'}>
                            <div className={classes.subHeaderText}> {this.localData.package_id ? 'CURRENT PACKAGE' : 'NO PACKAGE SELECTED' }</div><div className={classes.divider}/>
                        </Grid>

                        <Grid container justify={'center'}>
                        { this.localData.package_id ?
                            <Grid item className={classes.Accntcard}>
                                <Grid className={classes.cardShortInfo}>
                                    <Typography className={classes.pakgName}>{ this.state.packagename }</Typography>
                                    <Grid>
                                    <Typography className={classes.amount} style={{
                                            color:'#ff754a'
                                        }} >{ this.state.packageamount }
                                        <span className={classes.currency}>AED</span></Typography>
                                    </Grid>
                                    <Typography className={classes.usrCount}>UPTO { this.state.no_of_users } USERS</Typography>
                                </Grid>
                                <p className={classes.divider}></p>
                                
                                <Grid className={classes.accntBtnCardPts}>
                                    <Button className={classes.changeButton}
                                        onClick={this.handleChangePackage}
                                        >
                                        Change Package
                                    </Button>
                                    {
                                        this.localData.package_id != null ?
                                        this.localData.remaining_days < 15 ?
                                        <Button className={classes.renewButton}
                                          onClick={this.handleRenewPackage}
                                            >
                                            Renew Package
                                        </Button>
                                        :
                                        null
                                        :
                                        null
                                    }
                                </Grid>
                                <Grid className={classes.bottomImg}>
                                    <img src={cardBottom} alt="not found" />
                                </Grid>
                            </Grid>
                            :
                            <Grid className={classes.accntBtnCardPts}>
                            <Button className={classes.changeButton}
                                onClick={this.handleChangePackage}
                                >
                                Select Package
                            </Button>
                            </Grid>
                        }
                        </Grid>
                        <Grid container style={{ marginBottom:'30px' }} alignItems={'center'} justify={'flex-start'} direction={'row'}>
                            <div className={classes.subHeaderText}>TRANSACTION HISTORY</div><div className={classes.divider}/>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={1}>
                              <TableCustom heading={state.heading} rowsPerPage={state.rowsPerPage}
                                data={state.data} orderCallback={this.order}
                                callback={this.tableCallback} />
                            </Grid>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            </Fragment>
        )
    }
}

export default withStyles(styles)(observer(MyAccount));
