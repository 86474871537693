/***This file contain all the API calls***/

import axios from "axios";
// import { Toast } from './components/toast';
import { NotificationManager } from "react-notifications";
import { loader } from "./components/loader";


export const APIROOT = "https://demousrcfg.rosternow.com/user_service/api/v1/";
export const configService = "https://demousrcfg.rosternow.com/configuration_service/api/v1/";
export const schedularService = "https://demoschdatdce.rosternow.com/scheduler_service/api/v1/";
export const attendanceService = "https://demo.rosternow.com/attendence_service/";
export const chatService = "https://demoschdatdce.rosternow.com/chat_service/";
export const chatWs = "wss://demoschdatdce.rosternow.com/chat_service/";


//export const APIROOT = "http://34.74.69.18:8000/api/v1/";
// export const APIROOT = 'http://192.168.245.21:8000/api/v1/';
//export const configService = "http://34.74.69.18:8001/api/v1/";
////export const schedularService = "http://206.189.137.252:8001/api/v1/";
//export const notificationService = "http://206.189.137.252:8001/api/notification_service/";
//export const attendanceService = "http://134.209.154.165:8004/";
//export const chatService = "http://206.189.137.252:8000/";
//export const chatWs = "ws://206.189.137.252:8000/";

// export const APIROOT = process.env.REACT_APP_USER_SERVICE;
// console.log(process.env);
// export const APIROOT = 'http://192.168.245.21:8000/api/v1/';
// export const configService = process.env.REACT_APP_CONFIG_SERVICE;
// export const schedularService = process.env.REACT_APP_SCHEDULER_SERVICE;
// export const attendanceService = process.env.REACT_APP_ATTENDANCE_SERVICE;
// export const chatService = process.env.REACT_APP_CHAT_SERVICE;
// export const chatWs = process.env.REACT_APP_CHAT_WS;
// export const AutoschedulingService =
// process.env.REACT_APP_AUTOSCHEDULING_SERVICE;
//export const AutoschedulingService = "http://206.189.137.252:8792/api/v1/";

export const AutoschedulingService = "https://demoschdatdce.rosternow.com/auto_schedule_service/api/v1/"

function ReturnError(error) {
  let command = undefined;
  switch (error) {
    case 400:
      command = "Bad Request";
      break;
    case 401:
      command = "Unauthorized";
      NotificationManager.error(command);
      localStorage.clear();
      window.location.href = "/";
      break;
    case 403:
      command = "Forbidden";
      // NotificationManager.error("You don't have permisson for this feature.");
      break;
    case 409:
      command = "Conflict";
      break;
    case 500:
      command = "Server Error";
      NotificationManager.error("Unknown server error");
      break;
    case 502:
      command = "Timeout";
      NotificationManager.error("Server took too long to respond.");
    default:
      command = "No Error Match";
      break;
  }
  return { command };
}

export function getAuthToken() {
  return { Authorization: localStorage.getItem("Token") };
}

export function setAuthToken(token) {
  localStorage.setItem("Token", "Token " + token);
}

export function setLocalData(key, value) {
  localStorage.setItem(key, value);
}

export function getUserData() {
  if (localStorage.getItem("userData")) {
    return JSON.parse(localStorage.getItem("userData"));
  } else return null;
}

export default {
  POST(data, service, path, callback, hideLoader) {
    if (!hideLoader) {
      loader.show();
    }
    return axios
      .post(service + path, data, {
        headers: path === "forgot-password/" || path === "resend-otp/" || path === "login/" || path === "register/" ? {} : getAuthToken(),
      })
      .then((res) => {
        loader.hide();
        if (res.status === 201 || 200) {
          return callback(res);
        } else {
          return 0;
        }
      })
      .catch((err) => {
        loader.hide();

        if (!!err.response) {
          // console.log(err);
          console.log(ReturnError(err.response.status).command);
        }
        return callback(err.response);
      });
  },

  POSTEXPORT(data, service, path, callback, query) {
    loader.show();
    return axios
      .post(service + path, data, { headers: getAuthToken(), params: query })
      .then((res) => {
        loader.hide();
        if (res.status === 201 || 200) {
          return callback(res);
        } else {
          return 0;
        }
      })
      .catch((err) => {
        loader.hide();
        if (!!err.response) {
          // console.log(err);
          console.log(ReturnError(err.response.status).command);
        }
        return callback(err.response);
      });
  },

  GET(service, query, path, callback, hideLoader, stopAccountFilter) {
    if (!hideLoader) {
      loader.show();
    }
    let account_id = JSON.parse(localStorage.getItem("selectedAccount"));
    let pre_saved_team_id = localStorage.getItem("selectedTeam");
    let site_id = JSON.parse(localStorage.getItem("SITE-ID"));
    let userData = JSON.parse(localStorage.getItem("userData"));
    let params = Object.assign({}, query);
    if (
      !stopAccountFilter &&
      !!site_id &&
      !!userData &&
      userData.is_superadmin
    ) {
      //Actually the way site_id is being handled in get apis for super admin is
      //1. if there is default account filter than we are sending site-id from localstorage
      //this will come handy through out the project as we will be filtering out data on the basis of
      //account at most of the places.
      //2. if there is not default account filter than there can be 2 cases:
      //      i) Either we want to apply custom site-id than we can send it in params from the place
      //          where API is being called from.
      //      ii)Or we don't want to use site-id at all. Note that even in this case site-id will be
      //          in headers but the way backend has managed it is like in case of super admin they
      //          just check if site-id is present in headers but they won't filter data based
      //          on site-id, this not filtering is only happening in case of super admin login.
      params = Object.assign(params, { site_id: site_id });
    }
    if (
      !!account_id &&
      account_id !== null &&
      !stopAccountFilter &&
      !!!query.account_id
    ) {
      params = Object.assign(params, { account_id: account_id });
    }
    if (
      path === this.URLS.team &&
      !!!query.team_id &&
      !!pre_saved_team_id &&
      pre_saved_team_id !== null &&
      pre_saved_team_id.length
    ) {
      params = Object.assign(params, { account_id:account_id });
    }
    return axios
      .get(service + path, { params: params, headers: getAuthToken() })
      .then((res) => {
        if (!hideLoader) {
          loader.hide();
        }
        if (res.status === 200) {
          return callback(res);
        } else return 0;
      })
      .catch((err) => {
        loader.hide();
        if (!!err.response) {
          // console.log(err);
          console.log(ReturnError(err.response.status).command);
        }
       
        return callback(err.response);
      });
  },

  PATCH(service, data, path, callback, hideLoader) {
    if (!hideLoader) {
      loader.show();
    }
    axios
      .patch(service + path, data, { headers: getAuthToken() })
      .then((res) => {
        if (res.status === 200) {
          loader.hide();
          callback(res);
        }
        if (res.status === 204) {
          loader.hide();
          callback(res);
        }
      })
      .catch((err) => {
        loader.hide();
        if (!!err.response) {
          // console.log(err);
          console.log(ReturnError(err.response.status).command);
        }
     
        return callback(err.response);
      });
  },

  DELETE(service, data, path, callback, params) {
    loader.show();
    axios
      .delete(service + path + data, {
        params: params,
        headers: getAuthToken(),
      })
      .then((res) => {
        if (res.status === 204) {
          loader.hide();
          callback(res);
        }
      })
      .catch((err) => {
        loader.hide();
        if (!!err.response) {
          // console.log(err);
          console.log(ReturnError(err.response.status).command);
        }
       
        return callback(err.response);
      });
  },

  URLS: {
    register: "register/",
    login: "login/",
    changePassword: "change-password/",
    forgotPassword: "forgot-password/",
    resendOtp: "resend-otp/",
    resetPassword: "reset-password/",
    verifyOtpResetPassword: "verify-otp-reset-password/",
    verifyOtp: "verify-otp/",
    role: "role/",
    team: "team/",
    user: "user/",
    userupdate: "user/:id/",
    accounts: "accounts/",
    brand: "brand/",
    poi: "poi/",
    geographical: "geographical-area/",
    me: "me/",
    packages: "packages/",
    leave: "leave/",
    userIntraction: "users/interactions/",
    userConversations: "users/conversations/",
    getInteractions: "users/conversations/dashboard/",
    getRInteractions: "users/conversations/dashboard/recent/",
    timeOffRequest: "time-off-request/",
    timeOffApproveReject: "time-off-request-approve-reject/",
    shiftRequest: "shift-request/",
    shiftRequestApproveReject: "shift-approve-reject/",
    permission: "permission/",
    attendance: "attendance/",
    attendanceUpload: "attendance-upload/",
    attendanceDownload: "attendance-download/",
    attendanceExport: "attendance-export/",
    brandExport: "brands-export/",
    brandDownload: "brands-download/",
    brandUpload: "brands-upload/",
    dashboard: "dashboard/",
    poiDownload: "poi-download/",
    poiUpload: "poi-upload/",
    poiExport: "poi-export/",
    teamDownload: "model-download/",
    teamUpload: "team-upload/",
    teamExport: "team-export/",
    userDownload: "user-export/",
    userUpload: "user-upload/",
    userExport: "model-export/",
    roleDownload: "model-download/",
    roleUpload: "role-upload/",
    roleExport: "role-export/",
    linkingPoiTeam: "linking-poi-and-team/",
    dashboarddailydata: "dashboard-daily-data/",
    confirmpayment: "confirm-payment/",
    createpaypage: "create-paypage/",
    userpackage: "user/:id/",
    notifications: "ws/notification/",
    notification: "notification",
    transactionlist: "list-transactions/",
    packagesgetbymultipleids: "packages-getby-multiple_ids/",
    shift: "shift/",
    list_shift: "list-shift/",
    user_shift_list: "user-shift/",
    shift_template: "shift-template/",
    package_features: "features/",
    publishAndNotifyTeam: "team-publish-notify-shift/",
    publishAndNotifyUser: "user-publish-notify-shift/",
    kmlUpload: "kml-upload/",
    user_grouped_shifts: "user-grouped-shifts/",
    shiftExport: "shift-export/",
    shiftDownload: "shift-download/",
    shiftUpload: "shift-upload/",
    user_list: "user-list/",
    shift_weekly_copy: "shift-weekly-copy/",
    poiByMultipleIds: "poi-getby-multiple_ids/",
    break_time: "break-time/",
    user_by_teams: "user-by-teams/",
    schedulerExport: "scheduler-export/",
    singleShiftCopy: "single-shift-copy/",
    adminResetPassword: "admin-reset-password/",
    scheduledAvailableUserReport: "generate-scheduled-available-user-report/",
    attendanceLogs: "attendance-logs/",
    shiftLogs: "logs/",
    timeTracker: "time-tracker-data/",
    manualBreakTime: "manual-break-time/",
    markOffDay: "mark-user-off-day/",
    deleteOffDay: "delete-off-days/",
    forecastFile: "order-file/",
    forecastFileget: "get-order-file/",
    forecast: "calculate-ewma/",
    editForecastResult: "edit-result/",
    forecastGraph: "ewma-graph/",
    unassignedPoi: "unassigned-poi/",
    copyOffDay: "copy-off-days/",
    timeOffExtract: "time-off-extract/",
    shiftClocking: "shift-clocking/",
    calculateUnmannedShifts: "calculate-unmanned-shifts/",
    unmannedShiftsGraph: "unmanned-shifts-graph/",
    calculatedMannedShifts: "calculate-manned-shifts/",
    mannedShifts: "manned-shifts/",
    autoscheduleProgress: "autoschedule-progress/",
    assignedHours: "assigned-hours/",
    timeTrackerStats: "time-tracker-stats/",
    schedularCount: "scheduled-attended-data/",
    deleteShiftBulk: "shift-delete/",
    tasks: "tasks/",
    assignTasks: "assign-tasks/",
    customFields: "custom_fields/",
    orderDistribution: "distribute-orders/",
    partnerList: "partner-users/",
    updatePartnerAccess: "partner-access/",
    shiftRequestDetails: "shift-request-detail/",
    openShiftPublish: "publish-open-shift/",
    wage_type_options: "wage_type_options/",
    ratecard: "ratecard/",
    budget: "budget/",
    tool_tip_help: "tool_tip_help/"
  },
};

export function parseErrors(err) {
  let res = {};
  if (err.status === 400) {
    let dat = err.data || {};

    if (dat.hasOwnProperty("errors")) {
      res.error = dat.errors[0];
      delete dat.errors;
    }
    // if (dat.hasOwnProperty('non_field_errors')) {
    //     res.error = dat.non_field_errors[0];
    //     delete dat.non_field_errors;
    // }
    for (let key in dat) {
      if (dat.hasOwnProperty(key)) {
        res[key] = dat[key][0];
      }
    }
  } else {
    res.error = "network/server error";
  }
  return res;
}
