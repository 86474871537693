import React, { Component, Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { observable } from "mobx";
//computed on above line
//import loaderImg from "../assets/loader.gif";
//import clsx from "clsx";

const state = observable({
  open: false,
  style: {},
});

const styles = {
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    width: 0,
    transition: "all ease 1s",
    right: 0,
    height: 5,
    zIndex: "99999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#006BFF",
  },
};

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = { style: {} };
  }
  render() {
    const { classes } = this.props;
    setTimeout(() => {
      const newWidth = { width: `100%` };
      this.setState({
        style: newWidth,
      });
    }, 1000);
    return (
      <Fragment>
        {state.open ? (
          <Grid style={this.state.style} className={classes.loaderContainer}>
            {/* <img src={loaderImg} className={classes.loader} alt="loader img not found" /> */}
          </Grid>
        ) : null}
      </Fragment>
    );
  }
}

export default withStyles(styles)(observer(Loader));

const loader = {
  show: () => {
    state.open = true;
  },
  hide: () => {
    state.open = false;
  },
};
export { loader };
