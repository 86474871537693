import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { observer } from "mobx-react";
import del from "../../assets/garbage.svg";
import API, {APIROOT} from "./../../constants";
import { NotificationManager } from "react-notifications";
import { AppContext } from "../../Context/context";

const styles = {
  error: {
    color: "#ff0000",
    fontSize: "14px",
    paddingTop: "5px",
  },
  field: {
    border: "1px solid #999999",
    boxShadow: "none",
    borderRadius: "3px",
    width: "100%",
    padding: "10px",
  },
  action: {
    width: "100%",
    marginTop: "3rem",
    paddingTop: "2rem",
    justifyContent: 'flex-end',
    gap: '1rem',
    display: 'flex',
    borderTop: "1px solid rgb(0 0 0 / 10%)",
  },
  backbutton: {
    color: '#666666',
    backgroundColor: '#eaeaea'
  },
  delete: {
    cursor: 'pointer',
    marginLeft: 7,
    position: 'absolute',
    right: '-13px',
    top: '13px'
  },
  title: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: 15,
  },
};

const TeamModal = ({ backButton, classes, hideLocation }) => {
  const { dispatchTeamEvent } = useContext(AppContext);
  const [team, setTeam] = useState([""]);
  const [error, setError] = useState(null);
 
  const handleSubmit = (event) => {
    event.preventDefault();
    if(team[0] === ""){
      setError("Team field is required!")
    }else{
      const accID = localStorage.getItem('selectedAccount');
      const params = {
        team_list: team,
        account_id: accID
      }     
      API.POST(params, APIROOT, API.URLS.team , handleTeamCallback);
    }
  }

  const handleTeamCallback = (res) => {
    if(res && res.status === 201){
      if(res.data && res.data.Success.length > 0){
        const Team = res.data.Success.length > 1 ? 'Teams' : 'Team';
        NotificationManager.success(`${Team} added successfully.`);
        checkApis();
        dispatchTeamEvent('ADD_TEAM', true);
        hideLocation();
      } else if(res.data.Error.length > 0){
        res.data.Error.map( (msg)=> NotificationManager.error(msg))
      } else{
        NotificationManager.error("Something went wrong!");
      }
    } if(res && res.status === 400){
      res.data.team_list.map( d => NotificationManager.error(d))
    }
  }
  
  const checkApis = () => {
    API.GET(APIROOT,{},API.URLS.me,meCallback)
  }
  const meCallback = (res)=>{
    if(res && res.status === 200){
        localStorage.setItem('userData',JSON.stringify(res.data));
    }
  };

  const handleChange = (i,e) => { 
    const { value } = e.target;
    const list = [...team];
    list[i] = value;
    setTeam(list);
    setError("")
  };
  // adds new input
  const addMoreField = () => {
    setTeam([
      ...team,
      ""
    ])
  }
  // removes input
  const removeMoreField = (index) => {
    if (team.length !== 1) {
      const values = [...team]
      values.splice(index, 1)
      setTeam(values)
    }
  }

  /* const handleBack = () => {
    backButton();
  } */

  return (
    <div className="location-wrap">
      <h3 className={classes.title}>Enter team name</h3>
      <form onSubmit={handleSubmit}>
        {team && team.map( (f, i) => {
          return (
            <div key={i} className="new-fields">
              <input type="text" placeholder="e.g. Sales Team" name="team" className={classes.field} onChange={(e) => handleChange(i,e) } />
              {team.length !==1 && i > 0 && <img className={classes.delete} onClick={() => removeMoreField(i)} src={del} alt="Remove" />}
            </div>
          )
        } )}
        {error&&<div className={classes.error}>{error}</div>}
        <div className="text-right">
          <div className="addMore" onClick={addMoreField}>+Add more</div>
        </div>
        <div className={classes.action}>
        {/* <button
            type="button"
            className={`button ${classes.backbutton}`}
            onClick={handleBack}
          >
            Back
          </button> */}
          <button
            type="submit"
            className={`button ${classes.button}`}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default withStyles(styles)(observer(TeamModal));
