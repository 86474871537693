import React, { Component, Fragment } from 'react';
import { Typography, Button, Grid, Avatar, Snackbar, TextField, MenuItem, Checkbox, createMuiTheme, MuiThemeProvider, Select, Chip, Input, OutlinedInput, FormControl, InputLabel, InputAdornment, FormHelperText} from '@material-ui/core';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import styles from './styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {withStyles} from "@material-ui/core/styles/index";
import validatorjs from 'validatorjs';
import {format, lastDayOfMonth, startOfWeek, lastDayOfWeek, startOfMonth, endOfMonth, subMonths, subWeeks, addWeeks, addMonths} from 'date-fns';
import MySnackbarContentWrapper from './../../../components/Notification/snackbar';
import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import Photo from './../../../assets/user.png';
import API, {configService, parseErrors, schedularService, APIROOT, getUserData } from "../../../constants";
import {NotificationManager} from "react-notifications";
import difference from "../../../utils/diff";
import { countries } from './../../../utils/countries';
import ChangePassword from './ChangePassword.js';
import FindDialCode from "../../../utils/countrycodes";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import {findCountryCode} from '../../../utils/countrycodes';
import {removeSpace} from '../../../helper';
import clsx from 'clsx';

const state = observable({
    data:{},
    isError:false,
    errorMsg:'',
    roleid : 0
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#006BFF',
        },
        secondary: {
            main: '#006BFF',
        },
    }
});

const rules = {
    phone: {
        function: (value) => value.match(/^[+].[0-9]{7,12}$/),
        message: 'Length should be between 8-13 digits, excluding \'+\'',
    },
    password_regex: {
        // function: (value) => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/),
        // message: 'Password must contain uppercase, lowercase and digit.'
        function: (value) => {return value.length >= 6},
        message: 'Password must be at least 6 characters long.'
    },
};

const plugins = {
    dvr: dvr({
        package: validatorjs,
        extend: ({ validator, form }) => {
            Object.keys(rules).forEach((key) =>
                validator.register(key, rules[key].function, rules[key].message));
        },
    }),
};

function getCountryName(code){
    let country = countries.filter( (country) => {
        return country.isocode === code;
    });
    if(country && country.length){
        return country[0].name.short;
    }
    //console.log("Country", country, country[0]);
}

const fields = [{
    name: 'first_name',
    label: 'First Name',
    placeholder: 'First Name',
    rules: 'required|string|between:2,30',
},{
    name: 'last_name',
    label: 'Last Name',
    placeholder: 'Last Name',
    rules: 'string',
},{
    name: 'email',
    label: 'Email',
    placeholder: 'Email',
    rules: 'required|email|string'
},{
    name: 'phone_number',
    label: 'Phone Number',
    placeholder: 'Phone Number',
    rules: 'min:4|max:13|string',

    },{
    name: 'no_of_employees',
    label: 'No of Employees',
    placeholder: 'No of Employees',
    rules: 'integer|min:1',
    },
    {
    name: 'address',
    label: 'Address',
    placeholder: 'Address',
    rules: 'string',
},
    {
    name: 'city',
    label: 'City',
    placeholder: 'City',
    rules: 'required|string',
},
    {
    name: 'state',
    label: 'State',
    placeholder: 'State',
    rules: 'required|string',
},
    {
    name: 'pin_code',
    label: 'Postal Code',
    placeholder: 'Postal Code',
    rules: 'numeric',
},{
    name: 'country',
    label: 'Country',
    placeholder: 'Country',
    rules: 'required|string',
    type:'select'
    }];

const hooks = {
    onError(form) {
        console.log('All form errors', form.get());
    }
};

const form = new MobxReactForm({ fields }, { plugins });

class UserProfile extends Component{
    constructor(props){
        super(props);
        this.userData = getUserData();
        this.state = {
            first_name:'',
            last_name:'',
            address: '',
            city: '',
            state:'',
            pin_code: '',
            country: '',
            accounts: '',
            no_of_employees: 0,
            email: '',
            phone_number: '',
            profilePic:'',
            account_name:'',
            file:null,
            isEdit: false,
            isChangePassword: false,
            country_code:''
        };
        this.phoneFlagRef = React.createRef()
    }


    componentDidMount() {
        let currentComponent = this;
        let localData = JSON.parse(localStorage.getItem('userData'));
        state.isClient = localData.role_importance === 0;
        state.roleid = localStorage.role;
        
        API.GET(APIROOT, {}, API.URLS.me,
            function (me) {

                form.update({
                    first_name: me.data.first_name,
                    last_name: me.data.last_name,
                    email: me.data.email,
                    phone_number: !!me.data.phone_number?me.data.phone_number.number:'',
                    no_of_employees: me.data.no_of_employees,
                    address: me.data.address,
                    city: me.data.city,
                    state: me.data.state,
                    pin_code: me.data.pin_code,
                    country: me.data.country

                });

                currentComponent.setState({
                    first_name: me.data.first_name,
                    last_name: me.data.last_name,
                    accounts: me.data.account_id,
                    email: me.data.email,
                    phone_number: !!me.data.phone_number?me.data.phone_number.number:'',
                    no_of_employees: me.data.no_of_employees,
                    profilePic: me.data.profile_pic,
                    address: me.data.address,
                    city: me.data.city,
                    state: me.data.state,
                    pin_code: me.data.pin_code,
                    country: me.data.country,
                    country_code:!!me.data.phone_number?me.data.phone_number.country_code:''
                });

                state.data = {
                    first_name: me.data.first_name,
                    last_name: me.data.last_name,
                    email: me.data.email,
                    phone_number: !!me.data.phone_number?me.data.phone_number.number:'',
                    no_of_employees: me.data.no_of_employees,
                    address: me.data.address,
                    city: me.data.city,
                    state: me.data.state,
                    pin_code: me.data.pin_code,
                    country: me.data.country,
                    country_code:!!me.data.phone_number?me.data.phone_number.country_code:''

                };
               
                if (currentComponent.state.accounts) {
                    API.GET(configService, {}, API.URLS.accounts + currentComponent.state.accounts + '/',
                        function (acc) {
                            currentComponent.setState({
                                account_name: acc.data.account_name
                            });
                        });
                }
            });
    }

    handleEditProfile = () => {
        this.setState({
            isEdit: true
        },()=>{
            console.log(state.data.country_code);
            if (!!state.data.country_code){
                console.log(this.phoneFlagRef);
                this.phoneFlagRef.current.updateSelected(findCountryCode(state.data.country_code));
            }
        });
    }
    uploadPhoto = () => {
       // document.getElementById('contained-button-file').trigger('click');
    }

    setImage = (event) => {
        let $this = this;
        let localData = JSON.parse(localStorage.getItem('userData'));
        const url = API.URLS.user+localData.id+'/';

        this.setState({file: URL.createObjectURL(event.target.files[0])});

        const formData = new FormData();
        formData.append('profile_pic', event.target.files[0]);
        API.PATCH(APIROOT, formData, url, function(data){
            $this.setState({
                profilePic: data.data.profile_pic
            }, function(){
                localData.profile_pic = data.data.profile_pic;
                localStorage.setItem('userData',JSON.stringify(localData));
            });
            NotificationManager.success('Profile Uploaded!');
        });
    };

    handleUpdateProfile = () => {
        let localData = JSON.parse(localStorage.getItem('userData'));
        const url = API.URLS.user+localData.id+'/';
        let form_values = Object.assign({},form.values());
        form_values.country_code = this.state.country_code;
        let diff = difference(state.data, form_values);
        if (diff['phone_number'] || diff['country_code']){
            diff['phone_number'] = this.state.country_code+form_values['phone_number'];
            delete diff['country_code'];
        }

        // let diff = difference(state.data, form.values());
        if (!this.userData.role_importance === 0){
            delete diff['no_of_employees'];
        }
        if(this.userData.role_importance === 0 && diff['address'] == ""){
            NotificationManager.error("Billing Address is required");
        }
        else{
            API.PATCH(APIROOT, diff, url, (res) => {
                if(res.status == 200){
                    this.componentDidMount();
                    NotificationManager.success('Successfully Updated!');
                    this.setState({
                        isEdit: false
                    });
                }
                else if (res && res.status === 400){
                    if (Array.isArray(res.data)){
                        for (let i = 0;i<res.data.length;i++){
                            NotificationManager.error(res.data[i]);
                        }
                    }
                    else if (!!res.data.non_field_errors){
                        for (let i = 0;i<res.data.non_field_errors.length;i++){
                            NotificationManager.error(res.data.non_field_errors[i]);
                        }
                    }
                    else {
                        let error = parseErrors(res);
                        for (let key in error){
                            if(!!form.$(key) && form.$(key).validate()){
                                form.$(key).invalidate(error[key]);
                            }
                        }
                    }
                }
                return false;
            });
        }

    }


    handleChange = event => {
        this.setState({[event.target.name]:event.target.value});
        state.data.no_of_employees = event.target.value;
    }

    handleChangePassword = () => {
        form.clear();
        this.setState({
            isChangePassword: true
        });
    }
    showProfile = () => {
        console.log("Ambika1234@!");
        this.setState({
            isChangePassword: false
        });
    }
    setPhoneNumber = e => {
        let n = e.target.value;
        if(n.match(/^[0-9]{4,13}$/)){
            form.update({phone_number:n})
            form.$('phone_number').validate();
        }
        else{
            form.update({phone_number:n})
            form.$('phone_number').invalidate('Not a valid Phone Number');
        }

    }
    render(){
        const { classes } = this.props;
        const {isError, errorMsg, roleid} = state;
        return(
            <Fragment>
            {isError ?
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={isError}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                    >
                        <MySnackbarContentWrapper
                            onClose={this.handleClose}
                            variant="error"
                            message={ errorMsg }
                        />
                    </Snackbar>:null}
                <MuiThemeProvider theme={theme}>
                { this.state.isChangePassword ? 
                    <ChangePassword closeCallBack={this.showProfile}/>
                    :
                    this.state.isEdit ? 
                    <Grid
                        container
                        alignItems={'flex-start'}
                        justify={'center'}
                        direction={'row'}
                        style={{overflow:'scroll', margin:'0px 0px 0px 30px'}}
                        className={classes.formContainer}
                    >
                        <Grid item xs><Typography className={classes.headerText} testformtitle="edit-profile">Edit Profile</Typography></Grid>
                        <Grid item xs>
                            <Button
                                className={classes.saveBtn}
                                /*onClick={ this.handleUpdateProfile }*/
                                onClick={(e) => form.onSubmit(e, {
                                    onSuccess: this.handleUpdateProfile,
                                    onError: hooks.onError
                                })}
                                testformbtn="save"
                            >Save</Button>
                        </Grid>

                        <Grid className={classes.editFormContainer}>
                            <Grid container alignItems={'center'} justify={'flex-start'} direction={'row'}>
                                <div className={classes.subHeaderText}>DETAILS</div><div className={classes.divider}/>
                            </Grid>
                            <Grid container className={classes.spacing} justify={'center'}>
                                <input
                                    accept="image/*"
                                    name={'profile_pic'}
                                    style={{display:'none'}}
                                    id="contained-button-file"
                                    onChange={this.setImage}
                                    type="file"
                                    testforminput="user-profile-image"
                                />
                                <label htmlFor="contained-button-file">
                                    <img className={classes.profilePic} testformimage="user-avatar" src={this.state.profilePic !==null ? this.state.profilePic : Photo} alt={"Avatar"}/>
                                    <div className={classes.addPhoto} testformbtn="upload-photo">Upload Photo</div>
                                </label>
                            </Grid>
                            <Grid container>
                                <Grid item xs={4}>
                                <TextField
                                        label={'First Name'}
                                        placeholder={'First Name'}
                                        className={classes.textField}
                                        classes={{root:classes.textFieldRoot}}
                                        inputProps={{
                                            
                                            testforminput:"first-name"
                                        }}
                                        FormHelperTextProps={{
                                            
                                            testformerror:'first-name-error'
                                        }}
                                        {...form.$('first_name').bind()}
                                        error={!!form.$('first_name').error}
                                        helperText={form.$('first_name').error}
                                        variant={'outlined'}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                <TextField
                                        label={'Last Name'}
                                        placeholder={'Last Name'}
                                        className={classes.textField}
                                        classes={{root:classes.textFieldRoot}}
                                        inputProps={{
                                            
                                            testforminput:'last-name'
                                        }}
                                        FormHelperTextProps={{
                                            testformerror:"last-name-error"
                                        }}
                                        {...form.$('last_name').bind()}
                                        error={!!form.$('last_name').error}
                                        helperText={form.$('last_name').error}
                                        variant={'outlined'}
                                    />
                                </Grid>
                                {!this.userData.role_importance === 0?<Grid item xs={4}>
                                    <TextField
                                            label={'Accounts'}
                                            placeholder={'Accounts'}
                                            className={classes.textField}
                                            classes={{root:classes.textFieldRoot}}
                                            inputProps={{
                                                
                                                testforminput:'account'
                                            }}
                                            disabled
                                            value = { this.state.accounts ? this.state.accounts : '' }
                                            variant={'outlined'}
                                        />
                                </Grid>:null}
                            </Grid>
                                <Grid container>
                                    {this.userData.role_importance === 0?<Grid item xs={4}>
                                    <TextField
                                            label={'No of employees'}
                                            placeholder={'No of employees'}
                                            name={'no_of_employees'}
                                            className={classes.textField}
                                            classes={{root:classes.textFieldRoot}}
                                            inputProps={{
                                                
                                                testforminput:'no-of-employees'
                                            }}
                                            FormHelperTextProps={{
                                                testformerror:'no-of-employees-error'
                                            }}
                                            {...form.$('no_of_employees').bind()}
                                            error={!!form.$('no_of_employees').error}
                                            helperText={form.$('no_of_employees').error}
                                            variant={'outlined'}
                                        />
                                    </Grid>:null}
                                <Grid item xs={4}>
                                <TextField
                                        label={'Email'}
                                        placeholder={'Email'}
                                        className={classes.textField}
                                        classes={{root:classes.textFieldRoot}}
                                        inputProps={{
                                            
                                            testforminput:'email'
                                        }}
                                        FormHelperTextProps={{
                                            testformerror:'email-error'
                                        }}
                                        {...form.$('email').bind()}
                                        error={!!form.$('email').error}
                                        helperText={form.$('email').error}
                                        variant={'outlined'}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                <TextField
                                        label={'Phone No.'}
                                        placeholder={'Phone No.'}
                                        className={classes.textField}
                                        classes={{root:classes.textFieldRoot}}
                                        inputProps={{
                                            
                                            testforminput:'phone-number'
                                        }}
                                        FormHelperTextProps={{
                                            testformerror:'phone-number-error'
                                        }}
                                        InputProps={{
                                            classes:{
                                                adornedStart:classes.flagAdornedStart
                                            },
                                            startAdornment: <InputAdornment position="start" classes={{
                                                root:classes.flagRoot,
                                                positionStart:classes.flagPositionStart
                                            }} style={{marginBottom:0}}>
                                                <ReactFlagsSelect
                                                    defaultCountry="AE"
                                                    searchable={true}
                                                    ref={this.phoneFlagRef}
                                                    showSelectedLabel={false}
                                                    onSelect={(e) =>{
                                                        //document.getElementById('phone_number').value = "";
                                                        this.setState({country_code:FindDialCode(e)})
                                                    }}
                                                    className={classes.phoneFlag}
                                                />
                                            </InputAdornment>,
                                        }}
                                        {...form.$('phone_number').bind()}
                                        onChange={(e) => this.setPhoneNumber(e)}
                                        error={!!form.$('phone_number').error}
                                        helperText={form.$('phone_number').error}
                                        variant={'outlined'}
                                    />
                                </Grid>
                            </Grid>   
                                {this.userData.role_importance === 0?<Grid container className={clsx(classes.spacing,classes.billingSpacing)} alignItems={'center'} justify={'flex-start'} direction={'row'}>
                                <div className={classes.subHeaderText}>Billing Address</div><div className={classes.divider}/>
                            </Grid>:null}
                            <Grid container className={classes.spacing}>
                                {this.userData.role_importance === 0?<Grid item xs={12} className={classes.spacing}>
                                    <TextField
                                            className={classes.address}
                                            multiline
                                            label={'Address'}
                                            placeholder={'Address'}
                                            classes={{root:classes.textFieldRoot}}
                                            inputProps={{
                                                
                                                testforminput:'address'
                                            }}
                                            FormHelperTextProps={{
                                                testformerror:'address-error'
                                            }}
                                            {...form.$('address').bind()}
                                            error={!!form.$('address').error}
                                            helperText={form.$('address').error}
                                            variant={'outlined'}
                                        />
                                </Grid>:null}
                                <Grid item xs={4}>
                                    <TextField
                                            label={'City'}
                                            placeholder={'City'}
                                            className={classes.textField}
                                            classes={{root:classes.textFieldRoot}}
                                            inputProps={{
                                                
                                                testforminput:'city'
                                            }}
                                            FormHelperTextProps={{
                                                testformerror:'city-error'
                                            }}
                                            {...form.$('city').bind()}
                                            error={!!form.$('city').error}
                                            helperText={form.$('city').error}
                                            variant={'outlined'}
                                        /> 
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                            label={'State'}
                                            placeholder={'State'}
                                            className={classes.textField}
                                            classes={{root:classes.textFieldRoot}}
                                            inputProps={{
                                                
                                                testforminput:'state'
                                            }}
                                            FormHelperTextProps={{
                                                testformerror:'state-error'
                                            }}
                                            {...form.$('state').bind()}
                                            error={!!form.$('state').error}
                                            helperText={form.$('state').error}
                                            variant={'outlined'}
                                        />  
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                            label={'Postal Code'}
                                            placeholder={'Postal Code'}
                                            className={classes.textField}
                                            classes={{root:classes.textFieldRoot}}
                                            inputProps={{
                                                
                                                testforminput:'postal-code'
                                            }}
                                            FormHelperTextProps={{
                                                testformerror:'postal-code-error'
                                            }}
                                            {...form.$('pin_code').bind()}
                                            error={!!form.$('pin_code').error}
                                            helperText={form.$('pin_code').error}
                                            variant={'outlined'}
                                        /> 
                                </Grid>
                                <Grid item xs={4}>
                                <FormControl variant="outlined" className={classes.selectFormControl} error={!!form.$('country').error}>
                                    <InputLabel htmlFor={"select-country-input"} shrink>Country</InputLabel>
                                    <Select
                                        variant="outlined"
                                        displayEmpty
                                        input={<OutlinedInput id="select-country-input" labelWidth={70} notched/>}
                                        className={classes.textField}
                                        classes={{
                                            root:classes.textFieldRoot,
                                            selectMenu:clsx(classes.selectMenuClass,'country-menu')
                                        }}
                                        {...form.$('country').bind()}
                                        error={!!form.$('country').error}
                                        helperText={form.$('country').error}
                                        inputProps={{
                                            testforminput:'select-country'
                                        }}
                                    >
                                        <MenuItem value="" testformoptions="default-option">Country</MenuItem>
                                        {countries.map(option => (
                                            <MenuItem key={option.code} value={option.isocode} testformoptions={removeSpace(option.name.short)}>{option.name.short}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText testformerror="country-error" style={{ marginTop:-28, paddingLeft: 14 }} className="Mui-error">{form.$('country').error}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid> 
                        </Grid>           
                    </Grid>
                    :
                    <Grid
                        container
                        alignItems={'flex-start'}
                        justify={'center'}
                        direction={'row'}
                        style={{overflow:'scroll', margin:'0px 0px 0px 30px'}}
                    >
                        <Grid item xs>
                            <Typography className={classes.headerText} testformtitle="profile">Profile</Typography>
                        </Grid>
                        <Grid item xs>
                            <Grid container justify='flex-end'>
                                <Button
                                    className={classes.grayBtn}
                                    onClick={ this.handleChangePassword }
                                    testformbtn="change-password"
                                >Change Password</Button>
                                <Button
                                    className={classes.saveBtn}
                                    onClick={ this.handleEditProfile }
                                    testformbtn="edit"
                                >Edit</Button>
                            </Grid>
                        </Grid>

                        <Grid container alignItems={'center'} justify={'flex-start'} direction={'row'}>
                            <div className={classes.subHeaderText} >DETAILS</div><div className={classes.divider}/>
                        </Grid>

                        <Grid container justify={'center'} className={classes.spacing}>
                            <img className={classes.profilePic} testlabelimage="profile-preview-image" src={ this.state.profilePic ? this.state.profilePic : Photo } alt="Avatar" />
                        </Grid>
                        <Grid container className={classes.spacing}>
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>First Name: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="first-name">{ this.state.first_name }</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>Last Name: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="last-name">{ this.state.last_name }</InputLabel>
                            </Grid>
                            {!this.userData.role_importance === 0?<Grid item xs={4}>
                              <InputLabel className={classes.labelName}>Account: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="account-name">{ this.state.account_name }</InputLabel>
                            </Grid>:null}
                        </Grid>
                        <Grid container className={classes.spacing}>
                            {
                                this.userData.role_importance === 0 ?
                                <Grid item xs={4}>
                                    <InputLabel className={classes.labelName}>No. of employees: </InputLabel>
                                  <InputLabel className={classes.labelValue} testformlabel="no-of-employees">{ this.state.no_of_employees }</InputLabel>
                                </Grid>
                                : ''
                            }
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>Email: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="email">{ this.state.email }</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>Phone no: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="phone-no">{ this.state.country_code+this.state.phone_number }</InputLabel>
                            </Grid>
                        </Grid> 
                        <Grid container className={classes.spacing} alignItems={'center'} justify={'flex-start'} direction={'row'}>
                            <div className={classes.subHeaderText} >Billing Address</div><div className={classes.divider}/>
                        </Grid>  
                        <Grid container className={classes.spacing}>
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>Address: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="address">{ this.state.address }</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>City: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="city">{ this.state.city }</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>State: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="state">{ this.state.state }</InputLabel>
                            </Grid>
                        </Grid> 
                        <Grid container className={classes.spacing}>
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>Postal Code: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="postal-code">{ this.state.pin_code }</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <InputLabel className={classes.labelName}>Country: </InputLabel>
                              <InputLabel className={classes.labelValue} testformlabel="country">{ getCountryName(this.state.country) }</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                </MuiThemeProvider>
            </Fragment>
        )
    }
}

export default withStyles(styles)(observer(UserProfile));